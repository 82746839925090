import React from 'react'
import './style.css'

export default function Map() {
  return (
    <div className='Map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3892.4119672596075!2d79.98244587529838!3d12.686512621064095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52fc3f22492655%3A0xf11440d523a1dbac!2sECHO%20HMS%20LLP!5e0!3m2!1sen!2sin!4v1710760326711!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}
