import React from 'react'
import "./style.css"

export default function Vision() {
  return (
    <div className='Vision'>
      <span>
          <h2>Our Vision & Mission</h2>
          <p>
              Certainly! Our vision at Echo HMS is to revolutionize the medical billing industry by combining cutting-edge technology with exceptional customer service. We aim to provide swift, accurate, and hassle-free billing solutions for healthcare providers worldwide.<br /><br />
              Our mission is threefold:<br /><br />
              <ol>
                  <li>⁠Global Leadership: We aspire to be the preferred provider of medical billing services for healthcare organizations globally. By offering unparalleled value and expertise, we help our clients achieve financial stability and growth.</li>
                  <li>⁠Empowering Providers: We empower healthcare providers with innovative and efficient billing solutions. Our goal is to streamline processes, reduce costs, and enhance revenue cycle management, ultimately leading to improved patient outcomes and satisfaction.</li>
                  <li>⁠Ethical Excellence: We lead the industry in ethical and compliant medical billing practices. Our commitment to integrity ensures peace of mind for healthcare providers and fosters trust and respect within the healthcare community.</li>
              </ol><br />
              We constantly evolve our services to meet the dynamic needs of the healthcare industry, ensuring our clients stay ahead and achieve sustained success. Contact us today to explore how we can support your practice’s financial well-being.
          </p>
      </span>
    </div>
  )
}
