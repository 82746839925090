import React from 'react'
import './style.css'

export default function Contact() {
  return (
    <div className='Contact'>
        <div className='Contact_Left'>
          <h2><b>Contact</b> Us</h2>
        </div>
        <div className='Contact_Right'>
          <form onSubmit={(e)=>{
            e.preventDefault()
          }}>
            <label>Email Address</label>
            <input/>
            <div className='Contact_Right_Con'>
              <div className='Contact_Right_Con1'>
                <label>First Name</label>
                <input/>
              </div>
              <div className='Contact_Right_Con1'>
                <label>Last Name</label>
                <input/>
              </div>
            </div>
            <label>Company Name</label>
            <input/>
            <label>What can we help you with?</label>
            <select>
              <option hidden></option>
              <option>Medical Billing Serivces</option>
              <option>Domain Management & SEO</option>
              <option>Call Center Services</option>
              <option>Business Partnership</option>
              <option>Jobs</option>
            </select>
            <button>Submit</button>
          </form>
        </div>
        <div id='Contact' className='Contact_Ref'/>
    </div>
  )
}
