import React from 'react'
import './style.css'

export default function Introduction() {
  return (
    <div className='Introduction'>
        <span>
            <h2>Introduction</h2>
            <p>
                We are a team of seasoned experts dedicated to delivering top-quality medical billing services to healthcare providers. Recognizing that medical billing can be intricate and time-consuming, we strive to simplify the process for our clients.<br/><br/>
                At our company, we prioritize building strong client relationships. We work closely with each client to understand their unique needs, offering customized solutions tailored to their specific requirements. Our ultimate goal is to allow our clients to focus on delivering quality patient care while we handle their billing and administrative tasks.<br/><br/>
                We are committed to providing excellent customer service and support. Our responsive team is always available to address any questions or concerns our clients may have. With a team of highly skilled and experienced professionals who stay abreast of industry trends and regulations, we streamline our processes using advanced technology to achieve accuracy and efficiency in our billing services.
            </p>
        </span>
    </div>
  )
}
