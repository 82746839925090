import React from 'react'

export default function Premier() {
    return (
        <div className='Premier'>
            <span>
                <h2>Premier offshore Provider of Healthcare Services</h2>
                <p>
                    Certainly! At Echo HMS, our dynamic and results-driven team boasts over a decade of experience in multispecialty, multiplatform medical billing, coding, and other healthcare-related services.<br /><br />
                    Here’s what sets us apart:<br />
                    <h3>⁠Domain Expertise:</h3>Over the years, we’ve continually invested in evolving our healthcare domain expertise, tools, and technology. Our goal is to support US healthcare institutions with their unique requirements.<br />
                    <h3>Cost Efficiency:</h3>⁠We offer customized, seamless workflows supported by innovative methodologies and advanced technology. This approach ensures high-quality results and quicker turnaround times, ultimately leading to lower business costs.<br />
                    <h3>Long-Term Partnership:</h3>Our healthcare solutions are built on the foundation of establishing and maintaining long-term partnerships. We prioritize collaboration and sustained relationships with our clients.
                    If you’re seeking a reliable and experienced partner for your medical billing needs, consider Echo HMS. Contact us today to explore how we can support your practice’s success.
                </p>
            </span>
        </div>
    )
}
