import React from 'react'
import { AiOutlineTwitter, AiFillFacebook, AiFillInstagram } from 'react-icons/ai'
import "./style.css"

export default function Footer() {
  return (
    <div className='Footer'>
      <span>
        <p>© 2024 by Echo HMS</p>
        <div className='Footer_Social'>
          <a href='https://x.com' target='_blank'>
            <AiOutlineTwitter size={23} color='#00305b' />
          </a>
          <a href='https://facebook.com' target='_blank'>
            <AiFillFacebook size={23} color='#00305b' />
          </a>
          <a href='https://instagram.com' target='_blank'>
            <AiFillInstagram size={23} color='#00305b' />
          </a>
        </div>
      </span>
    </div>
  )
}
