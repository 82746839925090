import React from 'react'
import { Link } from 'react-router-dom'

export default function Functions() {
  return (
    <div className='Functions'>
        <div className='Functions_Left'>
            <h2><b>Our</b> Functions</h2>
        </div>
        <div className='Functions_Right'>
            <h2><b>Our</b> Functions</h2>
            <div className='Functions_Right_Con'>
                <div className='Functions_Right_Item'>
                    <div className='Functions_Right_Box'/>
                    <div>
                    <Link to={'/function/Medical-Coding-Services'}>
                        <h3>Medical Coding Services</h3>
                    </Link>
                    <p>This is the description</p>
                    </div>
                </div>
                <div className='Functions_Right_Item'>
                    <div className='Functions_Right_Box'/>
                    <div>
                    <Link to={'/function/Charge-Entry-and-Payment-Posting'}>
                        <h3>Charge Entry and Payment Posting</h3>
                    </Link>
                    <p>This is the description</p>
                    </div>
                </div>
                <div className='Functions_Right_Item'>
                    <div className='Functions_Right_Box'/>
                    <div>
                    <Link to={'/function/Insurance-Eligibility-Verifications'}>
                        <h3>Insurance Eligibility Verifications</h3>
                    </Link>
                    <p>This is the description</p>
                    </div>
                </div>
                <div className='Functions_Right_Item'>
                    <div className='Functions_Right_Box'/>
                    <div>
                    <Link to={'/function/AR-Follow-up-&-Denial-Management'}>
                        <h3>AR Follow up & Denial Management</h3>
                    </Link>
                    <p>This is the description</p>
                    </div>
                </div>
                <div className='Functions_Right_Item'>
                    <div className='Functions_Right_Box'/>
                    <div>
                    <Link to={'/function/Dental-Verification'}>
                        <h3>Dental Verification</h3>
                    </Link>
                    <p>This is the description</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
