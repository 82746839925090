import React, { useEffect } from 'react'
import "./style.css"
import { useParams } from 'react-router-dom'

export default function Funct() {
    const { id } = useParams()
    useEffect(()=>{
        document.getElementById("Funct").scrollIntoView({behavior:"smooth"})
    },[])

    return (
        <div id='Funct' className='Funct'>
            <img src={data?.find((e) => e.id === id)?.image} />
            <span>
                <h2>{data?.find((e) => e.id === id)?.name}</h2>
                <p dangerouslySetInnerHTML={{ __html: data?.find((e) => e.id === id)?.discr }}></p>
            </span>
        </div>
    )
}

const data = [
    {
        id: "Medical-Coding-Services", name: "Medical Coding Services", image: "https://images.unsplash.com/photo-1508830524289-0adcbe822b40?q=80&w=2850&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", discr: `Accurate and timely medical coding is crucial for healthcare providers to receive proper reimbursement and comply with regulations. At Echo HMS, we offer comprehensive coding services to help healthcare providers manage their revenue cycle effectively.<br/><br/>
        Our experienced and certified medical coders are proficient in major coding systems like ICD-10, CPT, and HCPCS. We collaborate closely with our clients to ensure accurate coding that aligns with regulatory requirements.<br/><br/>
        Our Medical Coding Services include:
        <ol>
        <li>⁠Initial coding of medical claims</li>
        <li>⁠Review and correction of previously coded claims</li>
        <li>⁠Ongoing coding support and training for healthcare providers</li>
        <li>⁠Medical coding audits to ensure compliance and identify areas for improvement
        </ol>
        <br/>
        We leverage the latest technology and industry best practices to enhance the accuracy and efficiency of our coding services. Our experts stay informed about regulatory changes and work closely with our billing team to submit claims accurately and promptly.
        Partner with Echo HMS for your medical coding needs and optimize your revenue cycle management. Contact us today to learn more about our services and how we can support your practice.`
    },
    {
        id:"Charge-Entry-and-Payment-Posting", name:"Charge Entry and Payment Posting", image:"https://images.unsplash.com/photo-1609429019995-8c40f49535a5?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", discr:`Certainly! Efficient and accurate charge entry and payment posting are crucial for healthcare providers to manage their revenue cycle effectively. At Echo HMS, we specialize in comprehensive charge entry and payment posting services, allowing healthcare providers to enhance their financial performance while maintaining a focus on delivering high-quality patient care.<br/><br/>
        Our Charge Entry services encompass the following key aspects:
        <ol>
        <li>Accurate Charge Capture: We meticulously record all charges for medical services provided, ensuring no revenue is left unaccounted for.</li>
        <li>⁠Thorough Review of Medical Records: Our team reviews medical records to guarantee precise and complete charge capture.</li>
        <li>⁠Regulatory Compliance: We ensure that all charges are appropriately coded to meet regulatory requirements.</li>
        </ol><br/>
        In addition, our Payment Posting services cover the following essential tasks:
        <ol>
        <li>⁠Payment Application: We post all payments received from insurance companies and patients to the correct patient accounts and claims.</li>
        <li>⁠Discrepancy Resolution: If there are any discrepancies or denials from insurance companies, we promptly address and resolve them.</li>
        </ol><br/>
        Our experienced and certified billing specialists leverage cutting-edge technology and industry best practices to optimize charge entry and payment posting. We tailor our services to meet the unique needs of each client, ensuring seamless revenue cycle management.<br/><br/>
        By partnering with Echo HMS, you can streamline your financial processes and focus on providing exceptional patient care. Contact us today to learn more about our services and how we can support your practice’s success`
    },
    {
        id:"Insurance-Eligibility-Verifications", name:"Insurance Eligibility Verifications", image:"https://images.unsplash.com/photo-1633158829585-23ba8f7c8caf?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", discr:`Certainly! Verifying patient insurance eligibility is a crucial step in the medical billing process. At Echo HMS, we provide comprehensive insurance eligibility verification services to assist healthcare providers in minimizing claim denials and optimizing their revenue cycle management.<br/><br/>
        Our insurance eligibility verification services encompass the following key aspects:
        <ol>
        <li>Coverage and Benefits Verification: We verify patient insurance coverage and benefits, ensuring accurate billing and reducing the risk of claim denials.</li>
        <li>⁠Pre-existing Conditions and Exclusions: We check for any pre-existing conditions or exclusions that may impact coverage.</li>
        <li>⁠Financial Details Confirmation: Our team confirms copayment, coinsurance, and deductible amounts to facilitate accurate billing.</li>
        <li>⁠Authorization and Referral Requirements: We verify whether prior authorization or referrals are necessary for specific services.</li>
        </ol>
        <br/>
        Our experienced billing specialists leverage advanced technology and industry best practices to enhance the efficiency and accuracy of our insurance eligibility verification services. We tailor our approach to meet the unique needs of each client, promoting streamlined revenue cycle management.<br/><br/>
        By partnering with Echo HMS, you can reduce billing errors, improve patient satisfaction, and achieve better financial outcomes. Contact us today to explore how our services can benefit your practice`
    },
    {
        id:"AR-Follow-up-&-Denial-Management", name:"AR Follow up & Denial Management", image:"https://images.unsplash.com/photo-1573164574230-db1d5e960238?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", discr:`Certainly! Efficient and accurate charge entry and payment posting are crucial for healthcare providers to manage their revenue cycle effectively. At Echo HMS, we provide comprehensive charge entry and payment posting services to assist healthcare providers in minimizing claim denials and optimizing their revenue cycle management.<br/><br/>
        Our charge entry services encompass the following key aspects:
        <ol>
        <li>Accurate Charge Capture: We meticulously record all charges for medical services provided, ensuring no revenue is left unaccounted for.</li>
        <li>⁠Thorough Review of Medical Records: Our team reviews medical records to guarantee precise and complete charge capture.</li>
        <li>⁠Regulatory Compliance: We ensure that all charges are appropriately coded to meet regulatory requirements.</li>
        </ol><br/>
        In addition, our payment posting services cover the following essential tasks:
        <ol>
        <li>⁠Payment Application: We post all payments received from insurance companies and patients to the correct patient accounts and claims.</li>
        <li>⁠Discrepancy Resolution: If there are any discrepancies or denials from insurance companies, we promptly address and resolve them.</li>
        </ol><br/>
        Our experienced and certified billing specialists leverage cutting-edge technology and industry best practices to optimize charge entry and payment posting. We tailor our services to meet the unique needs of each client, ensuring seamless revenue cycle management.
        By partnering with Echo HMS, you can streamline your financial processes and focus on providing exceptional patient care. Contact us today to explore how our services can benefit your practice`
    },
    {
        id:"Dental-Verification", name:"Dental Verification", image:"https://images.unsplash.com/photo-1516382799247-87df95d790b7?q=80&w=2948&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", discr:`Certainly! Verifying patient dental insurance coverage and benefits is a critical step in the dental billing process. At Echo HMS, we provide comprehensive dental insurance verification services to assist dental practices in minimizing claim denials and optimizing their revenue cycle management.<br/><br/>
        Our dental insurance verification services encompass the following key aspects:
        <ol>
        <li>⁠Coverage and Benefits Verification: We verify patient dental insurance coverage and benefits, ensuring accurate billing and reducing the risk of claim denials.</li>
        <li>⁠Pre-existing Conditions and Exclusions: We check for any pre-existing conditions or exclusions that may impact coverage.</li>
        <li>⁠Financial Details Confirmation: Our team confirms copayment, coinsurance, and deductible amounts to facilitate accurate billing.</li>
        <li>⁠Authorization and Referral Requirements: We verify whether prior authorization or referrals are necessary for specific services.</li>
        </ol>
        <br/>
        Our experienced billing specialists leverage advanced technology and industry best practices to enhance the efficiency and accuracy of our insurance eligibility verification services. We tailor our approach to meet the unique needs of each client, promoting streamlined revenue cycle management.
        By partnering with Echo HMS, you can reduce billing errors, improve patient satisfaction, and achieve better financial outcomes. Contact us today to explore how our services can benefit your practice`
    }
]