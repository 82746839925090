import React, { useEffect } from 'react'
import "./style.css"

export default function About() {
    useEffect(()=>{
        document.getElementById("About").scrollIntoView({behavior:"smooth"})
    },[])

    return (
        <div id='About' className='About'>
            <span>
                <h2>About our business</h2>
                <p>
                    Certainly! At Echo HMS, we specialize in providing exceptional medical billing services to healthcare providers of all sizes. Our core values include transparency, accuracy, and outstanding customer service. Here’s what sets us apart:<br /><br />
                    <ol>
                        <li>⁠Experienced Specialists: Our team of skilled medical billing specialists stays informed about the latest industry developments and regulatory changes. We adapt quickly to ensure our clients receive effective and efficient billing solutions.</li>
                        <li>⁠Comprehensive Services: We work with a diverse range of healthcare providers, including physicians, dentists, hospitals, and clinics. Our goal is to streamline billing processes and enhance revenue cycle management for each client.</li>
                        <li>⁠Responsive Support: We understand the complexities healthcare providers face. Our personalized customer service ensures that clients receive prompt answers to their questions and concerns.</li>
                        <li>⁠Peace of Mind: With us, healthcare providers can trust that their billing processes are handled meticulously. We pay attention to every detail, allowing our clients to focus on patient care.</li>
                    </ol><br />
                    If you’re seeking a reliable and experienced medical billing partner, consider Echo HMS. Contact us today to explore how our services can benefit your practice.
                </p>
            </span>
        </div>
    )
}
