import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import './index.css'
import Header from './layout/header'
import Hero from './pages/hero'
import Functions from './pages/functions'
import Introduction from './pages/introduction'
import Team from './pages/team'
import Contact from './pages/contact'
import Footer from './layout/footer'
import About from './pages/about';
import Vision from './pages/vision';
import Premier from './pages/premier';
import Service from './pages/service';
import Map from './pages/map';
import Funct from './pages/funct';

export default function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<Abouts/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/function/:id' element={<Functs/>}/>
        </Routes>
      </div>
    </Router>
  )
}

function Home() {
  return (
    <>
      <Header />
      <Hero />
      <Introduction />
      <Functions />
      <Team />
      <Contact />
      <Map/>
      <Footer />
    </>
  )
}

function Abouts() {
  return (
    <>
      <Header />
      <About/>
      <Vision/>
      <Premier/>
      <Footer />
    </>
  )
}

function Services() {
  return (
    <>
      <Header />
      <Service/>
      <Footer />
    </>
  )
}

function Functs(){
  return(
    <>
      <Header />
      <Funct/>
      <Footer />
    </>
  )
}