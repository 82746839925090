import React, { useState } from 'react'
import './style.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'

export default function Header() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <>
      <div className='Header'>
        <div className='Header_Con'>
          <h1><Link className='Header_Link1' to={'/'} onClick={() => {
            const hero = document.getElementById("Hero")
            if (hero) {
              hero.scrollIntoView({ behavior: "smooth" })
            }
          }}>Echo HMS</Link></h1>
          <ul>
            <li><Link className='Header_Link' to={'/'} onClick={() => {
              const hero = document.getElementById("Hero")
              if (hero) {
                hero.scrollIntoView({ behavior: "smooth" })
              }
            }}>Home</Link></li>
            <li><Link className='Header_Link' to={'/about'}>About</Link></li>
            <li><Link className='Header_Link' to={'/services'}>Services</Link></li>
            <li onClick={() => {
              const contact = document.getElementById("Contact")
              if (contact) {
                contact.scrollIntoView({ behavior: "smooth" })
              } else {
                navigate('/')
                setTimeout(() => {
                  const cont = document.getElementById("Contact")
                  cont.scrollIntoView({ behavior: "smooth" })
                }, 200);
              }
            }}>Contact</li>
            <li>Careers</li>
          </ul>
          <div className='Header_Icon' onClick={() => setOpen(!open)}>
            {open ?
              <AiOutlineClose size={23} color='#00305b' />
              :
              <AiOutlineMenu size={23} color='#00305b' />
            }
          </div>
        </div>
      </div>
      <div className={open ? 'Header_Menu Header_Menu_Open' : 'Header_Menu'}>
        <ul>
          <li><Link className='Header_Link' to={'/'} onClick={() => {
            const hero = document.getElementById("Hero")
            if (hero) {
              hero.scrollIntoView({ behavior: "smooth" })
            }
            setOpen(false)
          }}>Home</Link></li>
          <li><Link className='Header_Link' to={'/about'} onClick={()=>setOpen(false)}>About</Link></li>
          <li><Link className='Header_Link' to={'/services'} onClick={()=>setOpen(false)}>Services</Link></li>
          <li onClick={() => {
            const contact = document.getElementById("Contact")
            if (contact) {
              contact.scrollIntoView({ behavior: "smooth" })
            } else {
              navigate('/')
              setTimeout(() => {
                const cont = document.getElementById("Contact")
                cont.scrollIntoView({ behavior: "smooth" })
              }, 200);
            }
            setOpen(false)
          }}>Contact</li>
          <li>Careers</li>
        </ul>
      </div>
    </>
  )
}
