import React, { useEffect } from 'react'
import './style.css'
import { RiFileSearchLine, RiCalculatorLine, RiMoneyDollarCircleLine, RiFileForbidLine, RiFileList3Line, RiBillLine } from "react-icons/ri";

export default function Service() {
  
  useEffect(()=>{
    document.getElementById("Service").scrollIntoView({behavior:"smooth"})
  },[])
  
  return (
    <div id='Service' className='Service'>
        <span>
            <h2>Serivces</h2>
            <p>we specialize in providing a comprehensive suite of medical billing services to healthcare providers of all sizes. Our goal is to enhance revenue cycle management and streamline billing processes. Here are the key services we offer:</p>
            <div className='Service_Con'>
                <div className='Service_Item'>
                    <div className='Service_Img'>
                        <RiFileList3Line size={40} color='#00305b'/>
                    </div>
                    <h3>Claims Submission</h3>
                    <h4>Accurate and timely submission of medical claims to insurance companies, ensuring compliance with regulations and guidelines.</h4>
                </div>
                <div className='Service_Item'>
                    <div className='Service_Img'>
                        <RiFileSearchLine size={40} color='#00305b'/>
                    </div>
                    <h3>⁠Insurance Verification</h3>
                    <h4>Our team verifies patient insurance coverage and eligibility, minimizing claim rejections and ensuring precise billing.</h4>
                </div>
                <div className='Service_Item'>
                    <div className='Service_Img'>
                        <RiCalculatorLine size={40} color='#00305b'/>
                    </div>
                    <h3>Coding and Billing</h3>
                    <h4>Our experienced medical coders assign accurate codes to diagnoses and procedures, maximizing reimbursement for our clients.</h4>
                </div>
                <div className='Service_Item'>
                    <div className='Service_Img'>
                        <RiMoneyDollarCircleLine size={40} color='#00305b'/>
                    </div>
                    <h3>Payment Posting</h3>
                    <h4>We post payments and adjustments to patient accounts, reconciling them with insurance payments for precise billing.</h4>
                </div>
                <div className='Service_Item'>
                    <div className='Service_Img'>
                        <RiFileForbidLine size={40} color='#00305b'/>
                    </div>
                    <h3>Denial Management</h3>
                    <h4>Identifying and resolving claim denials promptly, we minimize delays and optimize revenue.</h4>
                </div>
                <div className='Service_Item'>
                    <div className='Service_Img'>
                        <RiBillLine size={40} color='#00305b'/>
                    </div>
                    <h3>Patient Billing and Collections</h3>
                    <h4>We manage patient billing and collections, ensuring up-to-date accounts and professional collection of outstanding balances.</h4>
                </div>
            </div>
        </span>
    </div>
  )
}
