import React from 'react'
import './style.css'

export default function Hero() {
  return (
    <div id='Hero' className='Hero'>
        <div className='Hero_Left'>
          <h2><b>MEDICAL</b> CODING</h2>
          <p>Unlock Precision in Healthcare Billing: Code Smart, Code Efficiently.</p>
        </div>
    </div>
  )
}
