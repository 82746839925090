import React from 'react'
import './style.css'

export default function Team() {
  return (
    <div className='Team'>
      <span></span>
    </div>
  )
}
